document.addEventListener('scroll', () => {
  // Seleciona todas as seções com a classe 'parallax'
  const sections = document.querySelectorAll('.parallax');

  // Configurações do Intersection Observer
  const observerOptions = {
    root: null, // Observa em relação ao viewport
    threshold: 0, // Executa quando qualquer parte da seção entra na tela
  };

  let ticking = false; // Controle para evitar chamadas excessivas

  // Função para lidar com o scroll
  const handleScroll = () => {
    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const offset = window.scrollY + rect.top;
      const speed = -0.5; // Ajuste a velocidade conforme necessário
      const backgroundPosition = (window.scrollY - offset) * speed;
      section.style.backgroundPositionY = `${backgroundPosition}px`;
    });

    ticking = false; // Libera para o próximo frame
  };

  // Adiciona o ouvinte de scroll com requestAnimationFrame
  window.addEventListener('scroll', () => {
    if (!ticking) {
      requestAnimationFrame(handleScroll);
      ticking = true;
    }
  });

  // Configura o Intersection Observer
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        handleScroll(); // Garante que o parallax seja ajustado ao entrar na tela
      }
    });
  }, observerOptions);

  // Observa todas as seções
  sections.forEach((section) => observer.observe(section));

});
document.querySelectorAll('.expand-btn').forEach((button) => {
  button.addEventListener('click', () => {
    const section = button.closest('.parallax');
    const hiddenContent = section.querySelector('.hidden-content');

    // Expande o conteúdo escondido
    hiddenContent.style.display = 'block';
    hiddenContent.classList.add('active');

    // Rola suavemente até o topo
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  });
});

document.querySelectorAll('.close-btn').forEach((button) => {
  button.addEventListener('click', () => {
    const section = button.closest('.parallax');
    const hiddenContent = section.querySelector('.hidden-content');

    // Fecha o conteúdo escondido
    hiddenContent.classList.remove('active');
    hiddenContent.classList.add('closing');

    // Aguarda o término da transição antes de ocultar completamente
    hiddenContent.addEventListener(
      'transitionend',
      () => {
        hiddenContent.classList.remove('closing');
        hiddenContent.style.display = 'none';

        // Reexibe o conteúdo original da seção
        section.querySelector('.title-initial').style.display = 'block';
      },
      { once: true } // Garante que o evento seja disparado apenas uma vez
    );

    // Rola suavemente de volta ao topo da seção
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  });
});

